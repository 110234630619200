import React, { Component, createRef } from "react";
import WheelComponent from "./wheel";
import { Box, Button, Typography, Grid, List, ListItem, ListItemText } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import "react-wheel-of-prizes/dist/index.css";
import "./styles.css";
import regionDescriptions from './regionDescriptions.json';
import regionCareServices from './regionCareServices.json';
import regionDescriptions2 from './regionDescriptions2.json';
import regionPlaces from './regionPlaces.json';
import regionRanks from './regionRanks.json';
import regionDescriptions3 from './regionDescriptions3.json';
import regionPlaces2 from './regionPlaces2.json';
import regionRatings from './regionRatings.json';
import regionDescriptions4 from './regionDescriptions4.json';
import regionPlaces3 from './regionPlaces3.json';
import regionNeuroteams from './regionNeuroteams.json';
import regionDescriptions5 from './regionDescriptions5.json';
import regionPlaces4 from './regionPlaces4.json';
import regionPlaces5 from './regionPlaces5.json';
import regions from './regions.json';

import arrow from './assets/arrow.svg';
import account from './assets/account.png';
import clock from './assets/clock.png';
import doc from './assets/doc.png';
import facebook from './assets/facebook.png';
import math from './assets/math.png';
import place from './assets/place.png';
import twitter from './assets/twitter.png';

const theme = createTheme({
  palette: {
    primary: {
      main: "#E0AFAF",
    },
  },
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      selectedRegion: "",
      activeStep: 0,
      isFinished: false,
    };
    this.wheelRef = createRef();
  }

  onFinished = (winner) => {
    const regionNameWithoutLan = winner.replace(" län", "");
    this.setState({ show: true, selectedRegion: regionNameWithoutLan, isFinished: true });
  };

  triggerSpin = () => {
    if (this.wheelRef.current) {
      this.wheelRef.current.spin();
      this.setState({ show: false, isFinished: false });
    }
  };

  highlightRegionName = (description, regionName) => {
    if (!description) return "";
    const regex = new RegExp(regionName, 'g');
    const parts = description.split(regex);
    return parts.reduce((acc, part, index) => {
      acc.push(part);
      if (index < parts.length - 1) {
        acc.push(<span key={index} style={{ color: '#E0AFAF', fontWeight: 'normal' }}>{regionName}</span>);
      }
      return acc;
    }, []);
  };

  highlightFirstNumber = (text) => {
    if (!text) return "";
    const parts = text.split(" ");
    return (
      <>
        <span style={{ color: '#E0AFAF', fontWeight: 'bold' }}>{parts[0]}</span> {parts.slice(1).join(" ")}
      </>
    );
  };

  handleNext = () => {
    this.setState({ activeStep: 1 }, () => {
      setTimeout(() => {
        this.triggerSpin();
      }, 100);
    });
  };

  handleRegionClick = (region) => {
    const regionNameWithoutLan = region.replace(" län", "");
    this.setState({ selectedRegion: regionNameWithoutLan, show: true, isFinished: true });
  };

  render() {
    const segments = Object.keys(regionDescriptions);
    const segColors = [
      "#FF0000", "#FFFFFF", "#FBEAE7", "#FF0000", "#FFFFFF", "#FBEAE7",
      "#FF0000", "#FFFFFF", "#FBEAE7", "#FF0000", "#FFFFFF", "#FBEAE7",
      "#FF0000", "#FFFFFF", "#FBEAE7", "#FF0000", "#FFFFFF", "#FBEAE7",
      "#FF0000", "#FFFFFF", "#FBEAE7"
    ];
    const textColors = [
      "#FFFFFF", "#000000", "#000000", "#FFFFFF", "#000000", "#000000",
      "#FFFFFF", "#000000", "#000000", "#FFFFFF", "#000000", "#000000",
      "#FFFFFF", "#000000", "#000000", "#FFFFFF", "#000000", "#000000",
      "#FFFFFF", "#000000", "#000000"
    ];

    const { selectedRegion, isFinished, show, activeStep } = this.state;

    const places = regionPlaces5[`${selectedRegion} län`] ? regionPlaces5[`${selectedRegion} län`].split(" av ") : ["", ""];

    return (
      <ThemeProvider theme={theme}>
        <Box sx={{ width: { xs: '100%', md: '40%' }, margin: '0 auto' }}>
          {activeStep === 0 ? (
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", p: 2, fontFamily: "'Exo', sans-serif" }}>
              <Box sx={{ width: "100%", textAlign: "center", p: 0 }}>
                <Box
                  className="description"
                  sx={{
                    maxWidth: "100%",
                    p: 0,
                    borderRadius: 1,
                    boxShadow: 1,
                    bgcolor: "white",
                  }}
                >
                  <Typography variant="h5" sx={{ bgcolor: "#E0AFAF", color: "#FFFFFF", p: 3, borderRadius: 1, fontSize: "32px", fontWeight: "bold", textAlign: "center" }}>
                    Rubrik
                  </Typography>
                  <Typography variant="body1" sx={{ p: 3, borderRadius: 1, fontSize: "18px", textAlign: "left" }}>
                    En text om vad ni vill uppnå och
                    bakgrunden. En lång text om vad
                    ni vill uppnå och bakgrunden. En
                    lång text om vad ni vill uppnå och
                    bakgrunden. En lång text om vad
                    ni vill uppnå och bakgrunden. En
                    lång text om vad ni vill uppnå och
                    bakgrunden. En lång text om vad
                    ni vill uppnå och bakgrunden.
                  </Typography>
                </Box>
                <Button 
                  variant="contained" 
                  sx={{ 
                    mt: 2, 
                    p: "10px 30px", 
                    width: "100%", 
                    bgcolor: "#FF0000", 
                    color: "white", 
                    border: "2px solid #FFFFFF",
                    borderRadius: "50px",
                    boxShadow: "none",
                    fontSize: "32px",
                    fontWeight: "bold",
                    textTransform: "none",
                    '&:hover': {
                      bgcolor: "#FF0000",
                      boxShadow: "none",
                    }
                  }}
                  onClick={this.handleNext}
                >
                  Snurra!
                </Button>
              </Box>
            </Box>
          ) : (
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", p: 2, fontFamily: "'Exo', sans-serif" }}>
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12}>
                  {isFinished ? (
                    <Box sx={{ 
                      maxWidth: "100%", 
                      p: 0, 
                      borderRadius: 1, 
                      boxShadow: 1, 
                      bgcolor: "white" 
                    }}>
                      <Typography variant="h5" sx={{ bgcolor: "#E0AFAF", color: "#FFFFFF", p: 3, borderRadius: 1, fontSize: "28px", fontWeight: "bold", textAlign: "center" }}>
                        {selectedRegion}
                      </Typography>
                      <Typography variant="body1" sx={{ p: 2, borderRadius: 1, fontSize: "18px", textAlign: "left" }}>
                        {this.highlightRegionName(regionDescriptions[`${selectedRegion} län`], selectedRegion)}
                      </Typography>
                      <Button 
                        variant="contained" 
                        sx={{ 
                          mt: 2, 
                          p: "20px 30px", 
                          width: "100%", 
                          bgcolor: "#FF0000", 
                          color: "white",
                          borderRadius: "50px",
                          border: "2px solid #FFFFFF",
                          boxShadow: "none",
                          fontSize: "28px",
                          fontWeight: "bold",
                          textTransform: "none",
                          '&:hover': {
                            bgcolor: "#FF0000",
                            boxShadow: "none",
                          }
                        }} 
                        fullWidth
                      >
                        Så ser det ut här
                      </Button>
                      <Typography variant="h5" sx={{ bgcolor: "#FFFFFF", color: "#000000", p: 2, borderRadius: 1, fontSize: "32px", fontWeight: "bold", textAlign: "center" }}>
                        Specialistvård
                      </Typography>
                      <Typography variant="h5" sx={{ bgcolor: "#FFFFFF", color: "#FF0000", borderRadius: 1, fontSize: "40px", fontWeight: "bold", textAlign: "center" }}>
                        {regionCareServices[`${selectedRegion} län`]}
                      </Typography>
                      <Typography variant="body1" sx={{ p: 1, borderRadius: 1, fontSize: "18px", textAlign: "left" }}>
                        {this.highlightRegionName(regionDescriptions2[`${selectedRegion} län`], selectedRegion)}
                      </Typography>
                      <Typography variant="body1" sx={{ p: 1, borderRadius: 1, fontSize: "24px", textAlign: "left" }}>
                        <strong>Plats:</strong> {this.highlightFirstNumber(regionPlaces[`${selectedRegion} län`])}
                        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                          <img src={arrow} alt="arrow" />
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
                          <Box component="img" src={clock} alt="clock" sx={{ height: '100px', width: '100px' }} />
                        </Box>
                      </Typography>

                      <Typography variant="h5" sx={{ bgcolor: "#FFFFFF", color: "#000000", p: 1, borderRadius: 1, fontSize: "32px", fontWeight: "bold", textAlign: "center" }}>
                        Korrekt diagnos
                      </Typography>
                      <Typography variant="h5" sx={{ bgcolor: "#FFFFFF", color: "#FF0000", borderRadius: 1, fontSize: "40px", fontWeight: "bold", textAlign: "center" }}>
                        {regionRanks[`${selectedRegion} län`]}
                      </Typography>
                      <Typography variant="body1" sx={{ p: 1, borderRadius: 1, fontSize: "18px", textAlign: "left" }}>
                        {this.highlightRegionName(regionDescriptions3[`${selectedRegion} län`], selectedRegion)}
                      </Typography>
                      <Typography variant="body1" sx={{ p: 1, borderRadius: 1, fontSize: "24px", textAlign: "left" }}>
                        <strong>Plats:</strong> {this.highlightFirstNumber(regionPlaces2[`${selectedRegion} län`])}
                        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                          <img src={arrow} alt="arrow" />
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
                          <Box component="img" src={math} alt="math" sx={{ height: '100px', width: '100px' }} />
                        </Box>
                      </Typography>

                      <Typography variant="h5" sx={{ bgcolor: "#FFFFFF", color: "#000000", p: 1, borderRadius: 1, fontSize: "32px", fontWeight: "bold", textAlign: "center" }}>
                        Betyg på vården
                      </Typography>
                      <Typography variant="h5" sx={{ bgcolor: "#FFFFFF", color: "#FF0000", borderRadius: 1, fontSize: "40px", fontWeight: "bold", textAlign: "center" }}>
                        {regionRatings[`${selectedRegion} län`]}
                      </Typography>
                      <Typography variant="body1" sx={{ p: 1, borderRadius: 1, fontSize: "18px", textAlign: "left" }}>
                        {this.highlightRegionName(regionDescriptions4[`${selectedRegion} län`], selectedRegion)}
                      </Typography>
                      <Typography variant="body1" sx={{ p: 1, borderRadius: 1, fontSize: "24px", textAlign: "left" }}>
                        <strong>Plats:</strong> {this.highlightFirstNumber(regionPlaces3[`${selectedRegion} län`])}
                        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                          <img src={arrow} alt="arrow" />
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
                          <Box component="img" src={account} alt="account" sx={{ height: '100px', width: '100px' }} />
                        </Box>
                      </Typography>

                      <Typography variant="h5" sx={{ bgcolor: "#FFFFFF", color: "#000000", p: 1, borderRadius: 1, fontSize: "32px", fontWeight: "bold", textAlign: "center" }}>
                        Eget neuroteam
                      </Typography>
                      <Typography variant="h5" sx={{ bgcolor: "#FFFFFF", color: "#FF0000", borderRadius: 1, fontSize: "40px", fontWeight: "bold", textAlign: "center" }}>
                        {regionNeuroteams[`${selectedRegion} län`]}
                      </Typography>
                      <Typography variant="body1" sx={{ p: 1, borderRadius: 1, fontSize: "18px", textAlign: "left" }}>
                        {this.highlightRegionName(regionDescriptions5[`${selectedRegion} län`], selectedRegion)}
                      </Typography>
                      <Typography variant="body1" sx={{ p: 1, borderRadius: 1, fontSize: "24px", textAlign: "left" }}>
                        <strong>Plats:</strong> {this.highlightFirstNumber(regionPlaces4[`${selectedRegion} län`])}
                        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                          <img src={arrow} alt="arrow" />
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
                          <Box component="img" src={place} alt="place" sx={{ height: '100px', width: '100px' }} />
                        </Box>
                      </Typography>

                      <Typography variant="h5" sx={{ bgcolor: "#FFFFFF", color: "#000000", borderRadius: 1, fontSize: "32px", fontWeight: "bold", textAlign: "center" }}>
                        Plats
                      </Typography>
                      <Typography variant="h5" sx={{ bgcolor: "#FFFFFF", color: "#FF0000", borderRadius: 1, fontSize: "50px", fontWeight: "bold", textAlign: "center" }}>
                        {places[0]}
                      </Typography>
                      <Typography variant="h5" sx={{ bgcolor: "#FFFFFF", color: "#000000", borderRadius: 1, fontSize: "32px", fontWeight: "bold", textAlign: "center" }}>
                        {` av ${places[1]} län`}
                      </Typography>

                      <Typography variant="body1" sx={{ p: 2, borderRadius: 1, fontSize: "18px", textAlign: "left" }}>
                        Hur ser det ut i ditt eget
                        län? Klicka i listan för att se
                        situationen där du bor!
                      </Typography>
                      <List>
                        {regions.map((region, index) => (
                          <ListItem
                            button
                            key={index}
                            sx={{
                              paddingTop: '0px',
                              paddingBottom: '0px',
                              marginTop: '0px',
                              marginBottom: '0px',
                              color: region === `${selectedRegion} län` ? '#E0AFAF' : 'inherit',
                            }}
                            onClick={() => this.handleRegionClick(region)}
                          >
                            <ListItemText primary={`${index + 1}. ${region}`} />
                          </ListItem>
                        ))}
                      </List>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                          <Box component="img" src={facebook} alt="facebook" sx={{ height: '80px', width: '80px', marginRight: '20px' }} />
                          <Box component="img" src={twitter} alt="twitter" sx={{ height: '90px', width: '90px', marginLeft: '20px' }} />
                        </Box>
                      </Box>
                      <Typography variant="h5" sx={{ bgcolor: "#FFFFFF", color: "#000000", p: 2, borderRadius: 1, fontSize: "32px", fontWeight: "bold", textAlign: "center" }}>
                        Dela med dig!
                      </Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>
                        <Box component="img" src={doc} alt="doc" sx={{ height: '100px', width: '100px' }} />
                      </Box>
                      <Typography variant="h5" sx={{ bgcolor: "#FFFFFF", color: "#000000", p: 2, borderRadius: 1, fontSize: "32px", fontWeight: "bold", textAlign: "center" }}>
                        Läs hela rapporten!
                      </Typography>
                      <Typography variant="body1" sx={{ p: 2, borderRadius: 1, fontSize: "18px", textAlign: "left" }}>
                        Den här rankingen bygger
                        på en underöskning som
                        Neuroförbundet gör årligen för
                        att uppmärksamma de regionala
                        skillnaderna i neurosjukvården.
                      </Typography>
                    </Box>
                  ) : (
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", overflow: "hidden", maxWidth: "100%" }}>
                      <WheelComponent
                        ref={this.wheelRef}
                        segments={segments}
                        segColors={segColors}
                        textColors={textColors}
                        winningSegment={null}
                        onFinished={this.onFinished}
                        primaryColor="#FF0000"
                        contrastColor="#FFFFFF"
                        buttonText=""
                        isOnlyOnce={false}
                        size={260} // Adjust the size for mobile view
                      />
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
      </ThemeProvider>
    );
  }
}

export default App;
